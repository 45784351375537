<template>
  <div class="tab_content_area">
    <div class="content_box profile beforetab">
      <!-- content_box -->
      <ul class="profile_list">
        <!-- style="display: flex" -->
        <template v-for="(p, i) in profileList">
          <li v-if="i < max" :key="'p-' + i" @click="getProfile(i, null, null, null)" :class="getMyProfile(i) ? 'my' : ''" style="position: relative;">
            <div class="tooltip_wrap short position_absolute" style="display: none; right: -80px;">
              <div class="cont" style="padding: 0px;">
                <ul>
                  <li>{{ p.preUserId }} <br> {{ p.userCtrCd }}</li>
                </ul>
              </div><!-- cont -->
            </div><!-- tooltip_wrap // -->
            <p :class="selectedIdx === i ? p.cls + ' selected-profile' : p.cls" @mouseover="showTooltipA" @mouseout="hideTooltipA">
              <img v-if="!isEmpty(p.picPotoString)" :src="p.picPotoString" alt="" />
              <img v-else-if="isEmpty(p.picPotoString) && !isEmpty(p.fileNm) && getFilePath(p.fileNm) != null" :src="getFilePath(p.fileNm)" alt="" />
              <img v-else :src="require('@/assets/images/pers/profile_noimg.png')" alt="" />
              <span v-if="p.useYn == 'N'">NO USE</span>
            </p>
            <p class="name">{{ isEmpty(p.picAlsNm) ? p.picNm : p.picAlsNm }}</p>
          </li>
        </template>
        <li class="more" id="more" @click="resetProfile('ADD')" v-if="(profileList.length == 0 && isEkmtc) || !isEkmtc">
          <a href="javascript:void(0)"><span class="offscreen"></span></a>
        </li>
      </ul>
      <p class="profile_tot" v-if="profileList.length > max">
        <a href="javascript:void(0)" @click="collapseProfile('open')">
          {{ $t("msg.MYIN050T010.013") }} (<span>{{ $t("msg.MYIN050T010.014") }} {{ profileList.length }}{{ $t("msg.MYIN050T010.015") }}</span>)</a>
        <span class="btn_icon icodown" />
      </p>
      <p class="profile_tot" v-else-if="profileList.length == max">
        <a href="javascript:void(0)" @click="collapseProfile('close')">{{ $t("msg.MYIN050T010.051") }}</a>
        <span class="btn_icon icoup" />
      </p>
    </div>

    <div class="content_box mt10" v-if="isSetProfile">
      <!-- content_box -->
      <table class="tbl_row">
        <colgroup>
          <col style="width: 17%" />
          <col style="width: 33%" />
          <col style="width: 17%" />
          <col style="width: 33%" />
        </colgroup>
        <tbody>
          <tr v-show="!isEmpty(profile.userId)">
            <th scope="row">{{ $t("msg.MYIN050T010.001") }}</th>
            <td colspan="3">
              <span>
                <input id="use1" type="radio" name="use" value="Y" v-model="profile.useYn" :checked="profile.useYn == 'Y'" />
                <label for="use1"><span>USE</span></label>
              </span>
              <span class="ml30">
                <input id="use2" type="radio" name="use" value="N" v-model="profile.useYn" :checked="profile.useYn == 'X'" />
                <label for="use2"><span>NO USE</span></label>
              </span>
            </td>
          </tr>

          <tr>
            <th scope="row">{{ $t("msg.MYIN050T010.003") }}</th><!-- 이름 -->
            <td>
              <template v-if="isEkmtc">
                <input type="text" maxlength="50" ref="inputPicNm" v-model="profile.picNm" id="picNm" />
              </template>
              <template v-else>
                <div style="display: flex">
                  <div style="width: 100%">
                    <e-auto-complete-pic-nm
                      ref="autoComplPicNm"
                    />
                    <input type="hidden" id="picNm" />
                  </div>
                </div>
              </template>
            </td>

            <th scope="row">{{ $t("msg.MYIN050T010.075") }}</th><!-- 이름(영어) -->
            <td>
              <template>
                <input type="text" v-model="profile.picEnm" id="picEnm" oninput="this.value = this.value.replace(/[^a-zA-Z0-9 ]/gi, '');" maxlength="50" />
              </template>
            </td>
          </tr>

          <tr>
            <th scope="row">{{ $t("msg.MYIN050T010.005") }}</th>
            <td>
              <select v-model="profile.picBizCatCd" id="picBizCatCd">
                <option v-for="(code, i) in chargeCodeList" :value="code.cd" :key="'charge-' + i">{{ code.cdNm }}</option>
              </select>
            </td>

            <th scope="row">{{ $t("msg.MYIN050T010.004") }}</th><!-- 담당 -->
            <td>
              <select v-model="profile.eiCatCd" id="eiCatCd">
                <option v-for="(cd, i) in eiCatCdList" :key="'eiCatCd-' + i" :value="cd.value">{{ cd.name }}</option>
              </select>
            </td>
          </tr>

          <tr>
            <th scope="row">{{ $t("msg.MYIN050T010.007") }}</th><!-- 팩스 -->
            <td>
              <div class="tbl_form">
                <span class="dv col_2">
                  <input type="text" v-model="profile.faxPlcNo" :class="faxCls" maxlength="3" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                </span>
                <span class="dv col_3">
                  <input type="text" v-model="profile.faxOfcNo" :class="faxCls" maxlength="12" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                </span>
              </div>
              <input type="hidden" id="fax" />
            </td>

            <th scope="row">{{ $t("msg.MYIN050T010.006") }}</th><!-- 전화 -->
            <td>
              <div class="tbl_form">
                <span class="dv col_2">
                  <input type="text" v-model="profile.telPlcNo" maxlength="3" :class="telCls" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                </span>
                <span class="dv col_3">
                  <input type="text" v-model="profile.telOfcNo" maxlength="25" :class="telCls" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                </span>
              </div>
              <input type="hidden" id="tel" />
            </td>
          </tr>

          <tr>
            <th scope="row">email</th>
            <td><input type="text" v-model="profile.emlAddr" id="emlAddr" /></td>
            <!-- <td class="border_left_none" /> -->
            <th scope="row">{{ $t("msg.MYIN050T010.008") }}</th><!-- 휴대폰 번호 -->
            <td>
              <div class="tbl_form">
                <span class="dv col_2">
                  <input type="text" v-model="profile.mbpBsnNo" :class="mbpCls" maxlength="3" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                </span>
                <span class="dv col_3">
                  <input type="text" v-model="profile.mbpOfcNo" :class="mbpCls" maxlength="12" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                </span>
              </div>
              <input type="hidden" id="mbp" />
            </td>
          </tr>

          <tr>
            <th scope="row">
              {{ $t("msg.MYIN050T010.066") }}<!-- 프로필명 -->
            </th>
            <td>
              <div style="display: flex">
                <div style="width: 100%">
                  <input type="text" v-model="profile.picAlsNm" id="picAlsNm" @input="inputPicAlsNm" maxlength="50" />
                </div>
                <div style="width: 25%; margin-left: 12px">
                  <input class="button blue sm" type="button" :value="$i18n.t('msg.MYIN050T010.071')" @click="checkDuplicationProfileName()" />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <th scope="row">{{ $t("msg.MYIN050T010.002") }}</th><!-- 프로필 썸네일 -->
            <td colspan="3">
              <div class="profile_thumbnail_file" @click="thumbnail_type = 'input'">
                <input type="radio" name="thumb-raido" id="thumb-input" v-model="thumbnail_type" value="input" />
                <label for="thumb-input"><span class="offscreen"></span></label>
                <span>
                  <input type="text" class="upload_name" v-model="uploadFileName" readonly id="uploadFile" />
                  <a class="button sm" href="javascript:void(0)" @click="openFileUploader()">{{ $t("msg.MYIN050T010.017") }}</a><!-- 파일첨부 -->
                </span>
                <div id="dext5_pop" v-show="isShowDext5Pop">
                  <div class="popup_dim" ref="popup">
                    <div class="popup_wrap" style="width: 800px; height: 520px">
                      <button class="layer_close" @click="closeFileUploadr()">close</button>
                      <div class="popup_cont">
                        <h1 class="page_title">{{ $t("msg.MYIN050T010.017") }}</h1><!-- 파일첨부 (팝업창 타이틀) -->
                        <div class="content_box">
                          <DEXT5Upload
                            ref="dextupload"
                            v-if="isShowDext5"
                            :id="'dext5-uploader-' + dext5Idx"
                            :single="true"
                            :category="false"
                            width="100%"
                            height="200px"
                            @completed="uploadProfileImage"
                            :file-info="uploadFileInfo"
                            file-size="50KB"
                            :category-info="[]"
                          />
                        </div>
                      </div>
                      <div class="mt10 text_center">
                        <a class="button blue lg" @click="closeFileUploadr()">{{ $t("msg.MYIN050T010.057") }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="profile_thumbnail_wrap">
                <span class="profile_thumbnail_item" v-for="(thumb, i) in defaultThumbnailList" :key="'thumb-' + i">
                  <input type="radio" name="thumb-raido" :id="'default-thumb-' + i" v-model="thumbnail_type" :value="thumb" />
                  <label :for="'default-thumb-' + i"><span class="offscreen"></span></label>
                  <span class="profile_thumbnail" @click="thumbnail_type = i">
                    <img :src="require('@/assets/images/myin/' + thumb)" alt="" />
                  </span>
                </span>
              </div>
            </td>
          </tr>

          <tr>
            <th scope="row">{{ $t("msg.MYIN050T010.009") }}</th><!-- 담당 지역/실화주 -->
            <td colspan="3">
              <div class="mt15 mb10">
                <!-- 담당 지역/실화주 -->
                <table class="tbl_col t2">
                  <colgroup>
                    <col style="width: 6%" />
                    <col style="width: 20%" />
                    <col style="width: 20%" />
                    <col style="width: 27%" />
                    <col style="width: 27%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="col">
                        <button class="tbl_icon plus" @click="addShj()">plus</button>
                      </th>
                      <th scope="col">FROM</th>
                      <th scope="col">TO</th>
                      <th scope="col">Actual Shipper</th>
                      <th scope="col">Actual Consignee</th>
                    </tr>
                    <tr v-if="profile.shjList.length == 0">
                      <td colspan="5">
                        <input type="hidden" id="emptyShjList" />
                      </td>
                    </tr>
                    <tr v-for="(shj, i) in profile.shjList" :key="'shj-' + i">
                      <td class="text_center">
                        <button class="tbl_icon minus" @click="deleteShj(i)">minus</button>
                      </td>
                      <td>
                        <select v-model="shj.fromCtrCd">
                          <option :value="null">ALL</option>
                          <option v-for="(country, j) in countryCodeList" :value="country.ctrCd" :key="'countryCode-' + i + '-' + j">{{ country.ctrEnm }}</option>
                        </select>
                      </td>
                      <td>
                        <select v-model="shj.toCtrCd">
                          <option :value="null">ALL</option>
                          <option v-for="(country, j) in countryCodeList" :value="country.ctrCd" :key="'countryCode-' + i + '-' + j">{{ country.ctrEnm }}</option>
                        </select>
                      </td>
                      <td>
                        <template v-if="auth.userCtrCd === 'KR'">
                          <e-auto-complete-cst-enm-v2
                            @change="updateShjV2"
                            :ctr-cd="defaultCtrCd"
                            :cd-nm="shj.shprNm"
                            type="shpr"
                            :idx="i.toString()"
                          />
                        </template>
                        <template v-else>
                          <e-auto-complete-cst-enm
                            @change="updateShj"
                            @objData="getObjData"
                            :ctr-cd="defaultCtrCd"
                            :cd-nm="shj.shprNm"
                            type="shpr"
                            :idx="i.toString()"
                          />
                        </template>
                        <input type="hidden" :id="'shprCd-' + i" />
                      </td>
                      <td>
                        <e-auto-complete-cst-enm
                          @change="updateShj"
                          @objData="getObjData"
                          :ctr-cd="defaultCtrCd"
                          :cd-nm="shj.cneNm"
                          type="cne"
                          :idx="i.toString()"
                        />
                        <input type="hidden" :id="'cneCd-' + i" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- 담당 지역 / 실화주 // -->
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t("msg.MYIN050T010.012") }}</th> <!-- 자동알림 서비스 신청 -->
            <td colspan="3">
              <div class="tbl_inner">
                <div class="tbl_inner_content">
                  <table class="tbl_row t3 mt15 mb15">
                    <colgroup>
                      <col style="width: 16%" />
                      <col style="width: 19%" />
                      <col style="width: auto" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th style="border-left: none"></th>
                        <td style="border-left: none"></td>
                        <td style="border-left: none; padding-left: 11px">
                          <input id="allCheck" type="checkbox" :checked="isAllCheck.all" />
                          <label for="'allCheck" @click="isAllCheckFn('ALL', null)">{{ $t("msg.MYIN050T010.073") }}</label><!-- 전체 선택 (상단) -->
                        </td>
                      </tr>
                      <tr>
                        <th :rowspan="getAlarmLength().ship" class="valign_top">{{ $t("msg.MYIN050T010.024") }}</th><!-- 선적 진행 현황 -->
                        <td class="checkbg">{{ $t("msg.MYIN050T010.073") }}</td><!-- 선적 진행 현황 -> 전체선택(좌측) -->
                        <td class="checkbg">
                          <ul class="grid form">
                            <li>
                              <input id="allCheckShipPush" type="checkbox" :checked="isAllCheck.ship.push" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('SHIP', 'PUSH')">{{ $t("msg.MYIN050T010.047") }}</label><!-- 전체선택 > 모바일 푸시 -->
                            </li>
                            <li>
                              <input id="allCheckShipPush" :disabled="!isKor" type="checkbox" :checked="isAllCheck.ship.talk" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('SHIP', 'TALK')">{{ $t("msg.MYIN050T010.048") }}</label><!-- 전체선택 > 카카오톡 알림톡 -->
                            </li>
                            <li>
                              <input id="allCheckShipPush" type="checkbox" :checked="isAllCheck.ship.eml" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('SHIP', 'EML')">{{ $t("msg.MYIN050T010.049") }}</label><!-- 전체선택 > 이메일 -->
                            </li>
                            <li>
                              <input id="allCheckShipPush" :disabled="!isKor" type="checkbox" :checked="isAllCheck.ship.sms" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('SHIP', 'SMS')">SMS</label> <!-- 전체선택 > SMS -->
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr v-for="(alarm, i) in profile.alarm.auto.ship" :key="'alarm-ship-' + i" v-show="showAlarm(alarm)">
                        <td>{{ alarm.title }}</td>
                        <td>
                          <ul class="grid form">
                            <li>
                              <!-- JP일때, 'T/S Port Departure-email' 비활성화 -->
                              <input :id="'alarm-ship-push-' + i" :disabled="alarm.altBizCatCd === '24' || alarm.altBizCatCd === '25'" type="checkbox" v-model="alarm.selectArr" :value="alarm.altBizCatCd === '24' ? '' : 'push'" />
                              <label :for="'alarm-ship-push-' + i">{{ $t("msg.MYIN050T010.047") }}</label><!-- 개별항목 > 모바일 푸시 -->
                            </li>
                            <li>
                              <input :id="'alarm-ship-talk-' + i" :disabled="!isKor || alarm.altBizCatCd === '25'" type="checkbox" v-model="alarm.selectArr" value="talk" />
                              <label :for="'alarm-ship-talk-' + i">{{ $t("msg.MYIN050T010.048") }}</label><!-- 개별항목 > 카카오 알림톡 -->
                            </li>
                            <li>
                              <input :id="'alarm-ship-eml-' + i" type="checkbox" v-model="alarm.selectArr" value="eml" />
                              <label :for="'alarm-ship-eml-' + i">{{ $t("msg.MYIN050T010.049") }}</label><!-- 개별항목 > 이메일 -->
                            </li>
                            <li>
                              <input :id="'alarm-ship-sms-' + i" :disabled="!isKor || alarm.altBizCatCd === '25'" type="checkbox" v-model="alarm.selectArr" value="sms" />
                              <label :for="'alarm-ship-sms-' + i">SMS</label><!-- 개별항목 > SMS -->
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <tr>
                        <th
                          :rowspan="getAlarmLength().req"
                          class="valign_top"
                        >
                          {{ $t("msg.MYIN050T010.025") }}<!-- 요청처리현황 -->
                        </th>
                        <td class="checkbg">{{ $t("msg.MYIN050T010.073") }}</td><!-- 요청처리현황 > 전체선택 -->
                        <td class="checkbg">
                          <ul class="grid form">
                            <li>
                              <input id="allCheckShipPush" type="checkbox" :checked="isAllCheck.req.push" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('REQ', 'PUSH')">{{ $t("msg.MYIN050T010.047") }}</label><!-- 요청처리현황 > 모바일푸시 -->
                            </li>
                            <li>
                              <input id="allCheckShipPush" :disabled="!isKor" type="checkbox" :checked="isAllCheck.req.talk" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('REQ', 'TALK')">{{ $t("msg.MYIN050T010.048") }}</label><!-- 요청처리현황 > 카카오알림톡 -->
                            </li>
                            <li>
                              <input id="allCheckShipPush" type="checkbox" :checked="isAllCheck.req.eml" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('REQ', 'EML')">{{ $t("msg.MYIN050T010.049") }}</label><!-- 요청처리현황 > 이메일 -->
                            </li>
                            <li>
                              <input id="allCheckShipPush" :disabled="!isKor" type="checkbox" :checked="isAllCheck.req.sms" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('REQ', 'SMS')">SMS</label><!-- 요청처리현황 > SMS -->
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr v-for="(alarm, i) in profile.alarm.auto.req" :key="'alarm-req-' + i" v-show="showAlarm(alarm)">
                        <td>
                          {{ alarm.title }}
                          <span v-if="i == 5" class="position_relative" @click="showTooltip(true)">
                            <button class="tbl_icon help"></button>
                            <div v-show="isToolTip" class="tooltip_wrap position_absolute" :style="etcTooltipStyle">
                              <!-- tooltip_wrap   -->
                              <div class="cont">
                                <!-- cont -->
                                <button class="close" @click="showTooltip(false)">close</button>
                                <ul class="bul_list_sm">
                                  <li>{{ $t("msg.MYIN050T010.041") }}</li>
                                </ul>
                              </div>
                              <!-- cont -->
                            </div>
                            <!-- tooltip_wrap // -->
                          </span>
                        </td>
                        <td>
                          <ul class="grid form">
                            <li>
                              <input :id="'alarm-req-push-' + i" type="checkbox" v-model="alarm.selectArr" value="push" />
                              <label :for="'alarm-req-push-' + i">{{ $t("msg.MYIN050T010.047") }}</label>
                            </li>
                            <li>
                              <input :id="'alarm-req-talk-' + i" :disabled="!isKor" type="checkbox" v-model="alarm.selectArr" value="talk" />
                              <label :for="'alarm-req-talk-' + i">{{ $t("msg.MYIN050T010.048") }}</label>
                            </li>
                            <li>
                              <input :id="'alarm-req-eml-' + i" type="checkbox" v-model="alarm.selectArr" value="eml" />
                              <label :for="'alarm-req-eml-' + i">{{ $t("msg.MYIN050T010.049") }}</label>
                            </li>
                            <li>
                              <input :id="'alarm-req-sms-' + i" :disabled="!isKor" type="checkbox" v-model="alarm.selectArr" value="sms" />
                              <label :for="'alarm-req-sms-' + i">SMS</label>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <tr>
                        <th :rowspan="getAlarmLength().anno" class="valign_top">{{ $t("msg.MYIN050T010.026") }}</th><!-- 안내사항 -->
                        <td class="checkbg">{{ $t("msg.MYIN050T010.073") }}</td><!-- 안내사항 > 전체선택 -->
                        <td class="checkbg">
                          <ul class="grid form">
                            <li>
                              <input id="allCheckShipPush" type="checkbox" :checked="isAllCheck.anno.push" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('ANNO', 'PUSH')">{{ $t("msg.MYIN050T010.047") }}</label>
                            </li>
                            <li>
                              <input id="allCheckShipPush" :disabled="!isKor" type="checkbox" :checked="isAllCheck.anno.talk" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('ANNO', 'TALK')">{{ $t("msg.MYIN050T010.048") }}</label>
                            </li>
                            <li>
                              <input id="allCheckShipPush" type="checkbox" :checked="isAllCheck.anno.eml" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('ANNO', 'EML')">{{ $t("msg.MYIN050T010.049") }}</label>
                            </li>
                            <li>
                              <input id="allCheckShipPush" :disabled="!isKor" type="checkbox" :checked="isAllCheck.anno.sms" />
                              <label for="'allCheckShipPush" @click="isAllCheckFn('ANNO', 'SMS')">SMS</label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr v-for="(alarm, i) in profile.alarm.auto.anno" :key="'alarm-anno-' + i" v-show="showAlarm(alarm)">
                        <td>{{ alarm.title }}</td>
                        <td>
                          <ul class="grid form">
                            <li>
                              <input :id="'alarm-anno-push-' + i" :disabled="alarm.altBizCatCd === '26'" type="checkbox" v-model="alarm.selectArr" value="push" />
                              <label :for="'alarm-anno-push-' + i">{{ $t("msg.MYIN050T010.047") }}</label><!-- 안내사항 > 모바일푸시 -->
                            </li>
                            <li>
                              <input :id="'alarm-anno-talk-' + i" :disabled="!isKor || alarm.altBizCatCd === '26'" type="checkbox" v-model="alarm.selectArr" value="talk" />
                              <label :for="'alarm-anno-talk-' + i">{{ $t("msg.MYIN050T010.048") }}</label><!-- 안내사항 > 카카오알림톡 -->
                            </li>
                            <li>
                              <input :id="'alarm-anno-eml-' + i" type="checkbox" v-model="alarm.selectArr" value="eml" />
                              <label :for="'alarm-anno-eml-' + i">{{ $t("msg.MYIN050T010.049") }}</label><!-- 안내사항 > 이메일 -->
                            </li>
                            <li>
                              <input :id="'alarm-anno-sms-' + i" :disabled="!isKor || alarm.altBizCatCd === '26'" type="checkbox" v-model="alarm.selectArr" value="sms" />
                              <label :for="'alarm-anno-sms-' + i">SMS</label><!-- 안내사항 > SMS -->
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- 안내사항 // -->
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('msg.MYIN010G010.018') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.017') }})</span></th> <!-- 개인정보수집동의 -->
            <td colspan="3">
              <input type="radio" id="rdo3" v-model="profile.mdtyPsnlInfCltnCnstYn" name="rdo1" value="Y" />
              <label for="rdo3" class="mr10"><span></span>{{ $t('msg.MYIN010G010.028') }}</label>
              <address-result class="ml30 button sm" @click.prevent="openPopup('MyInfoPersonalAgreePop')">{{ $t('msg.MYIN010G010.030') }}</address-result>
              <!-- {{ params.mdtyPsnlInfCltnCnstYn }}
                {{ mdtyPsnlInfCltnCnst }} -->
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('msg.MYIN010G010.020') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.019') }})</span></th> <!-- 개인정보수집동의서 -->
            <td colspan="3">
              <input type="radio" id="rdo5" v-model="profile.choPsnlInfCltnCnstYn" name="rdo2" value="Y" />
              <label for="rdo5" class="mr10"><span></span>{{ $t('msg.MYIN010G010.028') }}</label>
              <input type="radio" id="rdo6" v-model="profile.choPsnlInfCltnCnstYn" name="rdo2" value="N" />
              <label for="rdo6" class="mr10"><span></span>{{ $t('msg.MYIN010G010.029') }}</label>
              <a class="ml30 button sm" @click.prevent="openPopup('MyInfoPersonalAgreeChoicePop')">{{ $t('msg.MYIN010G010.030') }}</a>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('msg.MYIN010G010.021') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.019') }})</span></th> <!-- 마케팅 정보 수신 동의 -->
            <td colspan="3">
              <input type="radio" id="rdo7" v-model="profile.recvCnstYn" name="rdo3" value="Y" />
              <label for="rdo7" class="mr10"><span></span>{{ $t('msg.MYIN010G010.028') }}</label>
              <input type="radio" id="rdo8" v-model="profile.recvCnstYn" name="rdo3" value="N" />
              <label for="rdo8" class="mr10"><span></span>{{ $t('msg.MYIN010G010.029') }}</label>
              <a class="ml30 button sm" @click.prevent="openPopup('MyInfoMarketingAgree')">{{ $t('msg.MYIN010G010.030') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- content_box // -->
    <div class="flex_between">
      <div />
      <div>
        <a
          class="button blue lg mr5"
          href="javascript:void(0)"
          @click="saveProfileAlert()"
        >
          {{ $t("msg.MYIN050T010.050") }}</a><!-- 저장 -->
        <a
          class="button gray lg mr5"
          href="javascript:void(0)"
          @click="resetProfile('RESET')"
        >
          {{ $t("msg.MYIN050T010.065") }}</a><!-- 초기화 -->
        <!-- <a class="button gray lg" href="#">초기화</a> -->
      </div>
    </div>

    <div id="layerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePoup"
          @agreement="agreementPop"
          :parent-info="popupParams"
        />
      </win-layer-pop>
    </div>
  </div>
</template>
<script>
import { rootComputed, rootMethods } from '@/store/helpers'
import store from '@/store/index'
import ProfileApi from '@/api/rest/user/profile'
import AuthApi from '@/api/services/auth'

import EAutoCompleteCstEnm from '@/pages/user/EAutoCompleteCstEnmCustom'
import EAutoCompletePicNm from '@/pages/user/EAutoCompletePicNm'
import DEXT5Upload from '@/components/DEXT5Upload'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import MyInfoPersonalAgreePop from '@/pages/user/popup/MyInfoPersonalAgreePop'
import MyInfoPersonalAgreeChoicePop from '@/pages/user/popup/MyInfoPersonalAgreeChoicePop'
import MyInfoMarketingAgree from '@/pages/user/popup/MyInfoMarketingAgree'

const max = 9
export default {
  name: 'Profile',
  components: {
    EAutoCompleteCstEnm,
    EAutoCompletePicNm,
    DEXT5Upload,
    WinLayerPop,
    MyInfoPersonalAgreePop,
    MyInfoPersonalAgreeChoicePop,
    MyInfoMarketingAgree,
    'e-auto-complete-cst-enm-v2': () =>
      import('@/pages/user/EAutoCompleteCstEnmCustomV2')
  },
  props: [],
  data: function () {
    return {
      localization: {
        T016: this.$i18n.t('msg.MYIN050T010.016')
      },
      alarmTitleList: [
        '',
        '',
        this.$i18n.t('msg.MYIN050T010.027'),
        this.$i18n.t('msg.MYIN050T010.064'),
        this.$i18n.t('msg.MYIN050T010.029'),
        this.$i18n.t('msg.MYIN050T010.030'),
        this.$i18n.t('msg.MYIN050T010.052'),
        this.$i18n.t('msg.MYIN050T010.081'),
        this.$i18n.t('msg.MYIN050T010.082'),
        this.$i18n.t('msg.MYIN050T010.031'),
        this.$i18n.t('msg.MYIN050T010.032'),
        this.$i18n.t('msg.MYIN050T010.033'),
        'T/S Port Departure-email',
        this.$i18n.t('msg.MYIN050T010.034'),
        this.$i18n.t('msg.MYIN050T010.035'),
        this.$i18n.t('msg.MYIN050T010.036'),
        this.$i18n.t('msg.MYIN050T010.037'),
        this.$i18n.t('msg.CMBA380.DA01'),
        this.$i18n.t('msg.MYIN050T010.042'),
        this.$i18n.t('msg.MYIN050T010.043'),
        this.$i18n.t('msg.MYIN050T010.044'),
        this.$i18n.t('msg.MYIN050T010.083')
      ],
      max: max,
      eiCatCdList: [
        { name: this.$i18n.t('msg.MYIN050T010.018'), value: 'O' },
        { name: this.$i18n.t('msg.MYIN050T010.019'), value: 'I' },
        {
          name:
            this.$i18n.t('msg.MYIN050T010.018') +
            '/' +
            this.$i18n.t('msg.MYIN050T010.019'),
          value: 'A'
        }
      ],
      defaultThumbnailList: [
        'profile_img0101.png',
        'profile_img0102.png',
        'profile_img0103.png',
        'profile_img0104.png',
        'profile_img0105.png',
        'profile_img0201.png',
        'profile_img0202.png',
        'profile_img0203.png',
        'profile_img0204.png',
        'profile_img0205.png'
      ],
      profileList: [],
      simpleProfileList: [],
      profile: {
        alarm: {}
      },
      etcTooltipStyle: {
        width: '300px',
        left: '0',
        top: '24px'
      },
      isToolTip: false,
      isToolTipWait: false,
      countryCodeList: [],
      alarmCodeList: [],
      chargeCodeList: [],
      isSetProfile: false,
      currShjIdx: 0,
      currShjType: null,
      telCls: '',
      faxCls: '',
      mbpCls: '',
      thumbnail_type: 'input',
      uploadFileInfo: [],
      uploadFileName: '',
      isShowDext5: true,
      isShowDext5Pop: false,
      dext5Idx: 0,
      req: {},
      defaultCtrCd: null,
      isAllCheck: {
        all: false,
        ship: {
          push: false,
          talk: false,
          eml: false,
          sms: false
        },
        req: {
          push: false,
          talk: false,
          eml: false,
          sms: false
        },
        anno: {
          push: false,
          talk: false,
          eml: false,
          sms: false
        }
      },
      selectedIdx: null,
      autoSelectPic: null,
      isDuplcatedNickNm: true,
      originPicAlsNm: null,
      loginResp: null,
      beforeLoginResp: null,
      isEmptyProfile: true,
      isKor: false,
      isAddProfile: false,
      customComponent: null,
      popupParams: {}
    }
  },
  computed: {
    isEkmtc () {
      return sessionStorage.getItem('loginId').indexOf('EKMTC_') !== -1
    },
    ...rootComputed
  },
  watch: {
    profile: {
      deep: true,
      handler () {
        this.checkBoxControl()
        if (!this.isEmptyProfile) {
          this.updateValidationErrMsg()
        } else {
          this.isEmptyProfile = false
        }
      }
    },
    thumbnail_type: function () {
      if (this.thumbnail_type !== 'input') {
        this.$ekmtcCommon.hideErrorTooltip(
          document.querySelector('#uploadFile')
        )
      }
    },
    uploadFileInfo () {
      if (this.uploadFileInfo.length > 0) {
        this.uploadFileName = this.uploadFileInfo[0].originalName
        this.$ekmtcCommon.hideErrorTooltip(
          document.querySelector('#uploadFile')
        )
      } else {
        this.uploadFileName = ''
      }
    },
    uploadFileName () {
      console.log(this.uploadFileName)
    }
  },
  created () {
    // console.log('@@@@@ created  @@@@@')
    this.setCtrCd()
    this.getProfileList()
    if (!this.isEkmtc) {
      this.getSimpleProfileList()
    }
    this.getAlarmCodeList()
    this.getChargeCodeList()
    this.getCountryCodeList()
    this.openFileUploader()
    this.closeFileUploadr()
    this.isShowDext5Pop = true
    this.isAddProfile = false
    let selectedProfile = this.$store.getters['auth/selectedProfile']
    if (!this.isEmpty(selectedProfile.cstNo) && this.$route.params.checkView !== 'fromLogAppv') {
      if (this.$route.params.checkView === 'updateProfile') {
        this.selectedIdx = this.$route.params.profileIdx

        this.getProfile(null, this.$route.params.profileData.cstNo, this.$route.params.profileData.cstCatCd, this.$route.params.profileData.picNo)
      } else {
        this.getProfile(null, selectedProfile.cstNo, selectedProfile.cstCatCd, selectedProfile.picNo)
      }
    }
  },
  mounted () {
  },
  methods: {
    ...rootMethods,
    setCtrCd () {
      this.defaultCtrCd = this.isEmpty(this.memberDetail.userCtrCd) ? null : this.memberDetail.userCtrCd
      if (this.defaultCtrCd === null) {
        let type = navigator.appName
        let ctrCd = ''
        if (type === 'Netscape') {
          if (navigator.language === 'ko') {
            ctrCd = 'KR'
          } else {
            ctrCd = navigator.language.split('-')[1]
          }
        } else {
          ctrCd = navigator.userLanguage.split('-')[1]
        }
        this.isKor = ctrCd === 'KR'
      } else {
        this.isKor = this.defaultCtrCd === 'KR'
      }
    },
    checkDuplicationProfileName () {
      if (this.isEmpty(this.profile.picAlsNm)) {
        this.customAlert(this.$i18n.t('msg.MYIN050T010.067'))
      }
      let req = {
        picAlsNm: this.profile.picAlsNm,
        cstNo: sessionStorage.getItem('loginId').indexOf('EKMTC_') === -1 ? this.memberDetail.cstCd : 'KMT013'
      }
      //console.log(req)
      ProfileApi.checkDuplicationProfileName(req).then((response) => {
          let res = response.data
          let err_msg = ''
          if (res === 0) {
            //사용가능
            this.isDuplcatedNickNm = false
          } else if (res === 1) {
            if (this.originPicAlsNm === this.profile.picAlsNm) {
              //현재와 동일한 프로필명
              this.isDuplcatedNickNm = false
            }
          } else if (res > 1) {
            if (this.originPicAlsNm === this.profile.picAlsNm) {
              //동일한 프로필명이 존재해서 변경하는게 좋음.
              this.isDuplcatedNickNm = true
            } else {
              //불가능
              this.isDuplcatedNickNm = true
            }
          }
          if (this.isDuplcatedNickNm) {
            err_msg = this.$i18n.t('msg.MYIN050T010.068')
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#picAlsNm'), err_msg)
          } else {
            err_msg = this.$i18n.t('msg.MYIN050T010.069')
          }
          this.customAlert(err_msg)
          //
        })
        .catch((e) => {
          console.log(e)
        })
    },
    inputPicAlsNm (e) {
      if (this.profile.picAlsNm === this.originPicAlsNm) {
        this.isDuplcatedNickNm = false
      } else {
        this.isDuplcatedNickNm = true
      }
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#picAlsNm'))
    },
    checkBoxControl () {
      let isAllCheck = {
        all: true,
        ship: { push: false, talk: false, eml: false, sms: false },
        req: { push: false, talk: false, eml: false, sms: false },
        anno: { push: false, talk: false, eml: false, sms: false }
      }
      let allCheckCnt = { push: 0, talk: 0, eml: 0, sms: 0 }
      for (let i = 0; i < this.profile.alarm.auto.ship.length; i++) {
        for (let select of this.profile.alarm.auto.ship[i].selectArr) {
          if (select === 'push') {
            allCheckCnt.push++
          } else if (select === 'talk') {
            allCheckCnt.talk++
          } else if (select === 'eml') {
            allCheckCnt.eml++
          } else if (select === 'sms') {
            allCheckCnt.sms++
          }
        }
      }

      isAllCheck.ship.push = allCheckCnt.push === this.profile.alarm.auto.ship.length
      isAllCheck.ship.talk = allCheckCnt.talk === this.profile.alarm.auto.ship.length
      isAllCheck.ship.eml = allCheckCnt.eml === this.profile.alarm.auto.ship.length
      isAllCheck.ship.sms = allCheckCnt.sms === this.profile.alarm.auto.ship.length

      allCheckCnt = { push: 0, talk: 0, eml: 0, sms: 0 }

      for (let i = 0; i < this.profile.alarm.auto.req.length; i++) {
        for (let select of this.profile.alarm.auto.req[i].selectArr) {
          if (select === 'push') {
            allCheckCnt.push++
          } else if (select === 'talk') {
            allCheckCnt.talk++
          } else if (select === 'eml') {
            allCheckCnt.eml++
          } else if (select === 'sms') {
            allCheckCnt.sms++
          }
        }
      }

      isAllCheck.req.push = allCheckCnt.push === this.profile.alarm.auto.req.length
      isAllCheck.req.talk = allCheckCnt.talk === this.profile.alarm.auto.req.length
      isAllCheck.req.eml = allCheckCnt.eml === this.profile.alarm.auto.req.length
      isAllCheck.req.sms = allCheckCnt.sms === this.profile.alarm.auto.req.length

      allCheckCnt = { push: 0, talk: 0, eml: 0, sms: 0 }

      for (let i = 0; i < this.profile.alarm.auto.anno.length; i++) {
        for (let select of this.profile.alarm.auto.anno[i].selectArr) {
          if (select === 'push') {
            allCheckCnt.push++
          } else if (select === 'talk') {
            allCheckCnt.talk++
          } else if (select === 'eml') {
            allCheckCnt.eml++
          } else if (select === 'sms') {
            allCheckCnt.sms++
          }
        }
      }

      isAllCheck.anno.push = allCheckCnt.push === this.profile.alarm.auto.anno.length
      isAllCheck.anno.talk = allCheckCnt.talk === this.profile.alarm.auto.anno.length
      isAllCheck.anno.eml = allCheckCnt.eml === this.profile.alarm.auto.anno.length
      isAllCheck.anno.sms = allCheckCnt.sms === this.profile.alarm.auto.anno.length

      allCheckCnt = { push: 0, talk: 0, eml: 0, sms: 0 }

      if (this.isKor) {
        if (isAllCheck.ship.push && isAllCheck.ship.talk && isAllCheck.ship.eml &&
          isAllCheck.ship.sms && isAllCheck.req.push && isAllCheck.req.talk &&
          isAllCheck.req.eml && isAllCheck.req.sms && isAllCheck.anno.push &&
          isAllCheck.anno.talk && isAllCheck.anno.eml && isAllCheck.anno.sms) {
          isAllCheck.all = true
        } else {
          isAllCheck.all = false
        }
      } else {
        if (isAllCheck.ship.push && isAllCheck.ship.eml && isAllCheck.req.push &&
          isAllCheck.req.eml && isAllCheck.anno.push && isAllCheck.anno.eml) {
          isAllCheck.all = true
        } else {
          isAllCheck.all = false
        }
      }

      this.isAllCheck = isAllCheck
    },
    isAllCheckFn (category, type) {
      if (!this.isKor && (type === 'TALK' || type === 'SMS')) {
        //
      } else {
        let tempSelectArr = []
        let selectType = null
        if (type === 'PUSH') {
          selectType = 'push'
        } else if (type === 'TALK') {
          selectType = 'talk'
        } else if (type === 'EML') {
          selectType = 'eml'
        } else if (type === 'SMS') {
          selectType = 'sms'
        }

        if (category === 'ALL') {
          this.isAllCheck.all = !this.isAllCheck.all

          for (let i = 0; i < this.profile.alarm.auto.ship.length; i++) {
            if (this.isAllCheck.all) {
              if (this.isKor) {
                this.profile.alarm.auto.ship[i].selectArr = [
                  'push',
                  'talk',
                  'eml',
                  'sms'
                ]
              } else {
                this.profile.alarm.auto.ship[i].selectArr = ['push', 'eml']
              }
            } else {
              this.profile.alarm.auto.ship[i].selectArr = []
            }
          }

          for (let i = 0; i < this.profile.alarm.auto.req.length; i++) {
            if (this.isAllCheck.all) {
              if (this.isKor) {
                this.profile.alarm.auto.req[i].selectArr = [
                  'push',
                  'talk',
                  'eml',
                  'sms'
                ]
              } else {
                this.profile.alarm.auto.req[i].selectArr = ['push', 'eml']
              }
            } else {
              this.profile.alarm.auto.req[i].selectArr = []
            }
          }

          for (let i = 0; i < this.profile.alarm.auto.anno.length; i++) {
            if (this.isAllCheck.all) {
              if (this.isKor) {
                this.profile.alarm.auto.anno[i].selectArr = [
                  'push',
                  'talk',
                  'eml',
                  'sms'
                ]
              } else {
                this.profile.alarm.auto.anno[i].selectArr = ['push', 'eml']
              }
            } else {
              this.profile.alarm.auto.anno[i].selectArr = []
            }
          }
        } else if (category === 'SHIP') {
          if (type === 'PUSH') {
            this.isAllCheck.ship.push = !this.isAllCheck.ship.push
          } else if (type === 'TALK') {
            this.isAllCheck.ship.talk = !this.isAllCheck.ship.talk
          } else if (type === 'EML') {
            this.isAllCheck.ship.eml = !this.isAllCheck.ship.eml
          } else if (type === 'SMS') {
            this.isAllCheck.ship.sms = !this.isAllCheck.ship.sms
          }
          for (let i = 0; i < this.profile.alarm.auto.ship.length; i++) {
            tempSelectArr = JSON.parse(JSON.stringify(this.profile.alarm.auto.ship[i].selectArr))

            let isExist = false
            let eIdx = null
            let flag = false

            for (let j = 0; j < tempSelectArr.length; j++) {
              if (tempSelectArr[j] === selectType) {
                isExist = true
                eIdx = j
                break
              }
            }

            if (type === 'PUSH') {
              flag = this.isAllCheck.ship.push
            } else if (type === 'TALK') {
              flag = this.isAllCheck.ship.talk
            } else if (type === 'EML') {
              flag = this.isAllCheck.ship.eml
            } else if (type === 'SMS') {
              flag = this.isAllCheck.ship.sms
            }

            if (flag) {
              if (!isExist) {
                tempSelectArr.push(selectType)
              }
            } else {
              if (eIdx != null) {
                tempSelectArr.splice(eIdx, 1)
              }
            }

            this.profile.alarm.auto.ship[i].selectArr = tempSelectArr
          }
        } else if (category === 'REQ') {
          if (type === 'PUSH') {
            this.isAllCheck.req.push = !this.isAllCheck.req.push
          } else if (type === 'TALK') {
            this.isAllCheck.req.talk = !this.isAllCheck.req.talk
          } else if (type === 'EML') {
            this.isAllCheck.req.eml = !this.isAllCheck.req.eml
          } else if (type === 'SMS') {
            this.isAllCheck.req.sms = !this.isAllCheck.req.sms
          }

          for (let i = 0; i < this.profile.alarm.auto.req.length; i++) {
            tempSelectArr = JSON.parse(JSON.stringify(this.profile.alarm.auto.req[i].selectArr))

            let isExist = false
            let eIdx = null
            let flag = false

            for (let j = 0; j < tempSelectArr.length; j++) {
              if (tempSelectArr[j] === selectType) {
                isExist = true
                eIdx = j
                break
              }
            }

            if (type === 'PUSH') {
              flag = this.isAllCheck.req.push
            } else if (type === 'TALK') {
              flag = this.isAllCheck.req.talk
            } else if (type === 'EML') {
              flag = this.isAllCheck.req.eml
            } else if (type === 'SMS') {
              flag = this.isAllCheck.req.sms
            }

            if (flag) {
              if (!isExist) {
                tempSelectArr.push(selectType)
              }
            } else {
              if (eIdx != null) {
                tempSelectArr.splice(eIdx, 1)
              }
            }

            this.profile.alarm.auto.req[i].selectArr = tempSelectArr
          }
        } else if (category === 'ANNO') {
          if (type === 'PUSH') {
            this.isAllCheck.anno.push = !this.isAllCheck.anno.push
          } else if (type === 'TALK') {
            this.isAllCheck.anno.talk = !this.isAllCheck.anno.talk
          } else if (type === 'EML') {
            this.isAllCheck.anno.eml = !this.isAllCheck.anno.eml
          } else if (type === 'SMS') {
            this.isAllCheck.anno.sms = !this.isAllCheck.anno.sms
          }

          for (let i = 0; i < this.profile.alarm.auto.anno.length; i++) {
            tempSelectArr = JSON.parse(JSON.stringify(this.profile.alarm.auto.anno[i].selectArr))

            let isExist = false
            let eIdx = null
            let flag = false

            for (let j = 0; j < tempSelectArr.length; j++) {
              if (tempSelectArr[j] === selectType) {
                isExist = true
                eIdx = j
                break
              }
            }

            if (type === 'PUSH') {
              flag = this.isAllCheck.anno.push
            } else if (type === 'TALK') {
              flag = this.isAllCheck.anno.talk
            } else if (type === 'EML') {
              flag = this.isAllCheck.anno.eml
            } else if (type === 'SMS') {
              flag = this.isAllCheck.anno.sms
            }

            if (flag) {
              if (!isExist) {
                tempSelectArr.push(selectType)
              }
            } else {
              if (eIdx != null) {
                tempSelectArr.splice(eIdx, 1)
              }
            }

            this.profile.alarm.auto.anno[i].selectArr = tempSelectArr
          }
        }
      }
    },
    saveProfileAlert () {
      if (this.isDuplcatedNickNm && !(this.originPicAlsNm === this.profile.picAlsNm)) {
        this.customAlert(this.$i18n.t('msg.MYIN050T010.070')) // 프로필명의 중복 여부를 확인해 주세요
        window.scrollTo(0, 0)
        return
      }

      if (this.profile.useYn === 'N' && this.profile.picNo === this.selectedProfile.picNo) {
        this.customAlert(this.$i18n.t('msg.MYIN050T010.074')) // 현재 선택된 프로필의 사용여부를 'NO USE' 상태로 변경할 수 없습니다.
        return
      }

      let req = JSON.parse(JSON.stringify(this.profile))
      // console.log('@@@@ req ', req)
      // console.log('@@@@ this.profile ', this.profile)

      if (!this.isEkmtc) {
        req.picNm = this.$refs.autoComplPicNm.keyword
      }

      req.alarm = this.setReqAlarm(req.alarm)
      let isValidation = this.validation(req)
      let addProfile = false

      if (this.$route.params.profile === 'N') {
        // if (this.$route.params.checkView === 'beforeProfile') {
          addProfile = false
        // } else {
        //   addProfile = true
        // }
      } else if (this.isAddProfile) {
        addProfile = true
      } else {
        addProfile = false
      }

      // console.log('addProfile @@@@ ', addProfile)

      if (isValidation) {
        if (addProfile) {
          setTimeout(() => {
                  this.$router.push(
                    {
                      name: 'login-approval',
                      params: {
                        checkView: 'fromProfile',
                        profile: 'N',
                        req: this.parseReqProfile(req),
                        profileData: this.profile
                      }
                    })
          }, 100)
        } else {
          this.req = req
          let obj = {
            onConfirm: this.saveProfile,
            customCloseBtnClass: 'button blue lg',
            customCloseBtnText: this.$i18n.t('msg.MYIN050T010.057'),
            customConfirmBtnText: this.$i18n.t('msg.MYIN050T010.056'),
            useConfirmBtn: true,
            customConfirmBtnClass: 'button blue lg',
            message: this.$i18n.t('msg.MYIN050T010.058'),
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
        }
      }
    },
    saveProfile () {
      ProfileApi.saveProfile(this.parseReqProfile(this.req)).then((response) => {
          if (this.profile.useYn === 'Y') {
            let selectedProfile = response.data
            selectedProfile.tmpEiCatCd = ''
            store.commit('auth/selectedProfile', selectedProfile)
            window.scrollTo(0, 0)
            this.profileList = []
            this.changeProfile(selectedProfile)
          } else {
            this.customAlert(this.$i18n.t('msg.ONIM099P070.005'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    changeProfile (selectedProfile) {
      let req = {
        userId: selectedProfile.userId,
        jwt: this.$store.state.auth.loginInfo.jwt
      }

      // GA dataLayer 추가
      window.dataLayer[0].user_profile = selectedProfile.picNm
      window.dataLayer[0].ga_user_id = selectedProfile.userId
      window.dataLayer[0].id = selectedProfile.preUserId
      window.dataLayer[0].is_login = 'Y'

      window.dataLayer.push({
        event: 'profile_change',
        ga_user_id: selectedProfile.userId,
        user_contact_region: selectedProfile.userCtrCd,
        user_member_type: selectedProfile.cstCatCd,
        user_pic: selectedProfile.eiCatCd,
        is_login: 'Y',
        user_profile: selectedProfile.picNm,
        id: selectedProfile.preUserId
      })

      // profile pre_user_id 로 변경
      if (!this.isEmpty(selectedProfile.preUserId) && sessionStorage.getItem('loginId') !== selectedProfile.preUserId) {
        sessionStorage.setItem('loginId', selectedProfile.preUserId)
        // [[ 로그인 추가 프로필 변경 시 user_id가 변경되면 재 로그인...
        let upperForm = {
            userId: selectedProfile.preUserId,
            userPwd: selectedProfile.preUserId
          }
        AuthApi.logInfo(upperForm).then(loginResp => {
          let respCode = loginResp.headers.respcode
          // console.log('loginResp==========>', loginResp)
          if (respCode === 'C0000') {
            this.beforeLoginResp = loginResp.data
            let cstCd = null
            if (upperForm.userId.indexOf('EKMTC_') === -1) {
              sessionStorage.setItem('cstCd', loginResp.data.cstCd)
            } else {
              sessionStorage.setItem('cstCd', 'KMT013')
            }
            sessionStorage.setItem('loginId', upperForm.userId)
            sessionStorage.setItem('logCatCd', loginResp.data.logCatCd)

            req.jwt = this.beforeLoginResp.data.jwt
          }
        }).catch(e => {
          console.log(e)
        })
        // 로그인 추가 ]]
      }

      AuthApi.profileLogin(req).then((resp) => {
          if (this.isEmpty(this.beforeLoginResp)) {
           this.beforeLoginResp = JSON.parse(JSON.stringify(this.$store.state.auth.loginInfo))
          }
          this.loginResp = resp
          this.saveLoginData(selectedProfile)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    saveLoginData (selectedProfile) {
      let respCode = this.loginResp.headers.respcode
      let respMsg = this.$i18n.t(`js.auth.${respCode}`)
      let jwt = this.isEkmtc
        ? this.beforeLoginResp.jwt
        : this.loginResp.data.jwt

      // EKMTC 직원 일때, 세션 데이터 수정
      // if (this.beforeLoginResp.staffFlag === 'Y' && respCode !== 'C0000') {
      //   respCode = 'C0000'
      //   this.loginResp.data = this.beforeLoginResp
      // }

      if (respCode === 'C0000') {
        this.saveLogin({
          user: this.loginResp.data,
          jwt: jwt,
          autoLogin: true,
          profile: selectedProfile,
          loginInfo: this.beforeLoginResp
        }).then((r) => {
            this.$store.commit('updatePersonalizedFlag')
            this.$store.commit('updateIsOpenPersonalized', true)
            setTimeout(() => { this.$router.push('/main').catch(() => {}) }, 300)
          }).catch((r) => {
            console.log(r)
          })
      } else {
        // console.log('프로필저장 후 프로필로그인 실패')
      }
    },
    parseReqProfile (req) {
      let loginId = sessionStorage.getItem('loginId')
      let res = {
        profile: req,
        alarmList: this.paresReqAlarm(req.alarm),
        shjList: req.shjList,
        uploadFile:
          this.thumbnail_type === 'input' && this.uploadFileInfo.length > 0
            ? this.uploadFileInfo[0]
            : null
      }

      if (this.thumbnail_type === 'input') {
        if (this.uploadFileInfo.length > 0) {
          res.uploadFile = this.uploadFileInfo[0]
        }
        res.profile.fileNm = null
      } else {
        res.profile.fileNm = this.thumbnail_type
        res.uploadFile = null
      }

      if (loginId.indexOf('EKMTC_') === -1) {
        req.cstNo = this.memberDetail.cstCd
        res.loginId = loginId //로그인한 ID
      } else {
        req.cstNo = 'KMT013'
        res.loginId = this.auth.userId //로그인한 ID
      }

      if (this.isEmpty(req.cstCatCd)) {
        req.cstCatCd = '01'
      }

      if (this.isEmpty(req.userId)) {
        res.profile.userId = null
      }

      return res
    },
    validation (req) {
      let flag = true
      let err_msg = ''
      let alertMsg = null
      if (this.thumbnail_type === 'input') {
        if (this.uploadFileInfo.length === 0) {
          err_msg = this.$i18n.t('msg.MYIN050T010.059')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#uploadFile'), err_msg)
          flag = false
          if (alertMsg === null) {
            alertMsg = err_msg
          }
        }
      }

      if (req.picNm.length > 50) {
        err_msg = '이름은 최대 50자 까지 가능합니다.'

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#picNm'), err_msg)
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      }

      if (this.isEmpty(req.picNm)) {
        if (this.auth.serviceLang === 'KOR') {
          err_msg =
            this.$i18n.t('msg.MYIN050T010.003') +
            this.$i18n.t('msg.MYIN050T010.053')
        } else {
          err_msg =
            this.$i18n.t('msg.MYIN050T010.053') +
            ' ' +
            this.$i18n.t('msg.MYIN050T010.003')
        }

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#picNm'), err_msg)
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      }

      if (this.isEmpty(req.mdtyPsnlInfCltnCnstYn)) {
        err_msg = this.$t('msg.MYIN010G010.023')

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#rdo3'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.choPsnlInfCltnCnstYn)) {
        err_msg = this.$t('msg.MYIN010G010.025')

        this.$ekmtcCommon.showErrorTooltip(
          document.querySelector('#rdo5'),
          err_msg
        )
        flag = false
      }

      if (this.isEmpty(req.recvCnstYn)) {
        err_msg = this.$t('msg.MYIN010G010.024')

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#rdo7'), err_msg)
        flag = false
      }

      if (this.isEmpty(req.picEnm)) {
        if (this.auth.serviceLang === 'KOR') {
          err_msg = this.$i18n.t('msg.MYIN050T010.075') + this.$i18n.t('msg.MYIN050T010.053')
        } else {
          err_msg = this.$i18n.t('msg.MYIN050T010.053') + ' ' + this.$i18n.t('msg.MYIN050T010.075')
        }

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#picEnm'), err_msg)
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      }

      if (this.isEmpty(req.picAlsNm)) {
        if (this.auth.serviceLang === 'KOR') {
          err_msg = this.$i18n.t('msg.MYIN050T010.066') + this.$i18n.t('msg.MYIN050T010.053')
        } else {
          err_msg = this.$i18n.t('msg.MYIN050T010.053') + ' ' + this.$i18n.t('msg.MYIN050T010.066')
        }
        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#picAlsNm'), err_msg)
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      }

      if (this.isEmpty(req.telOfcNo) || this.isEmpty(req.telPlcNo)) {
        if (this.auth.serviceLang === 'KOR') {
          err_msg = this.$i18n.t('msg.MYIN050T010.006') + this.$i18n.t('msg.MYIN050T010.054')
        } else {
          err_msg = this.$i18n.t('msg.MYIN050T010.054') + ' ' + this.$i18n.t('msg.MYIN050T010.006')
        }

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#tel'), err_msg)
        this.telCls = 'input_error'
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      } else {
        if (req.telOfcNo.length > 26 || req.telPlcNo.length > 3) {
          err_msg = this.$i18n.t('msg.MYIN050T010.061')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#tel'), err_msg)
          this.telCls = 'input_error'
          flag = false
          if (alertMsg === null) {
            alertMsg = err_msg
          }
        }
      }

      if (this.isEmpty(req.faxOfcNo) || this.isEmpty(req.faxPlcNo)) {
        if (this.auth.serviceLang === 'KOR') {
          err_msg = this.$i18n.t('msg.MYIN050T010.007') + this.$i18n.t('msg.MYIN050T010.054')
        } else {
          err_msg = this.$i18n.t('msg.MYIN050T010.054') + ' ' + this.$i18n.t('msg.MYIN050T010.007')
        }

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#fax'), err_msg)
        this.faxCls = 'input_error'
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      } else {
        if (req.faxOfcNo.length > 12 || req.faxPlcNo.length > 3) {
          err_msg = this.$i18n.t('msg.MYIN050T010.062')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#fax'), err_msg)
          this.faxCls = 'input_error'
          flag = false
          if (alertMsg === null) {
            alertMsg = err_msg
          }
        }
      }

      if (this.isEmpty(req.mbpBsnNo) || this.isEmpty(req.mbpOfcNo)) {
        if (this.auth.serviceLang === 'KOR') {
          err_msg = this.$i18n.t('msg.MYIN050T010.008') + this.$i18n.t('msg.MYIN050T010.054')
        } else {
          err_msg = this.$i18n.t('msg.MYIN050T010.054') + ' ' + this.$i18n.t('msg.MYIN050T010.008')
        }

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#mbp'), err_msg)
        this.mbpCls = 'input_error'
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      } else {
        if (req.mbpBsnNo.length > 3 || req.mbpOfcNo.length > 12) {
          err_msg = this.$i18n.t('msg.MYIN050T010.063')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#mbp'), err_msg)
          this.mbpCls = 'input_error'
          flag = false
          if (alertMsg === null) {
            alertMsg = err_msg
          }
        }
      }

      if (this.isEmpty(req.emlAddr)) {
        if (this.auth.serviceLang === 'KOR') {
          err_msg = this.$i18n.t('msg.MYIN050T010.049') + this.$i18n.t('msg.MYIN050T010.053')
        } else {
          err_msg = this.$i18n.t('msg.MYIN050T010.053') + ' ' + this.$i18n.t('msg.MYIN050T010.049')
        }

        this.$ekmtcCommon.showErrorTooltip(document.querySelector('#emlAddr'), err_msg)
        flag = false
        if (alertMsg === null) {
          alertMsg = err_msg
        }
      } else {
        //console.log(req.emlAddr)
        let chkEmail = req.emlAddr.split(',')
        let regExp =
          /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
        //console.log(chkEmail)

        if (chkEmail.length > 5) {
          alertMsg = this.$t('msg.ADD20220221.025') // email주소는 최대 5명까지 등록 가능합니다.
          flag = false
        }

        chkEmail.forEach((vo) => {
          // if (vo.match(regExp) == null) {
          if (!this.$ekmtcCommon.checkEmail(vo)) {
            err_msg = this.$i18n.t('msg.MYIN050T010.060')
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#emlAddr'), err_msg)
            flag = false

            if (alertMsg === null) {
              alertMsg = err_msg
            }
          }
        })
      }
      if (alertMsg !== null) {
        this.customAlert(alertMsg)
        window.scrollTo(0, 0)
      }
      return flag
    },
    fnEmailValidate () {
      let isOk = true
      let chkVal = this.send.reqCstEmlAddr.split(',')

      if (chkVal.length > 5) {
        this.openAlert(this.$t('msg.ADD20220221.025')) // email주소는 최대 5명까지 등록 가능합니다.
        isOk = false
      }

      return isOk
    },
    updateValidationErrMsg () {
      if (!this.isEmpty(this.profile.picNm)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#picNm'))
      }

      if (!this.isEmpty(this.profile.picEnm)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#picEnm'))
      }

      if (!this.isEmpty(this.profile.picAlsNm)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#picAlsNm'))
      }

      if (!this.isEmpty(this.profile.emlAddr)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#emlAddr'))

        let chkEmail = this.profile.emlAddr.split(',')
        let regExp =
          /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
        let err_msg = ''

        if (chkEmail.length > 5) {
          err_msg = this.$t('msg.CSBL200.271') // email주소는 최대 5명까지 등록 가능합니다.
          this.$ekmtcCommon.showErrorTooltip(
            document.querySelector('#emlAddr'),
            err_msg
          )
        } else {
          this.$ekmtcCommon.hideErrorTooltip(
            document.querySelector('#emlAddr')
          )

          chkEmail.forEach((vo) => {
            if (vo !== '') {
              // if (vo.match(regExp) != null) {
              if (this.$ekmtcCommon.checkEmail(vo)) {
                this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#emlAddr'))
              } else {
                // if (vo.match(regExp) == null) {
                if (!this.$ekmtcCommon.checkEmail(vo)) {
                  err_msg = this.$i18n.t('msg.MYIN050T010.060')
                  this.$ekmtcCommon.showErrorTooltip(document.querySelector('#emlAddr'), err_msg)
                }
              }
            }
          })
        }
      }

      if (this.profile.telOfcNo.length <= 26 && this.profile.telOfcNo.length >= 1 && this.profile.telPlcNo.length <= 3 &&
        this.profile.telPlcNo.length >= 1 && !this.isEmpty(this.profile.telOfcNo) && !this.isEmpty(this.profile.telPlcNo)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#tel'))
        this.telCls = ''
      }

      if (this.profile.faxOfcNo.length <= 12 && this.profile.faxOfcNo.length >= 1 && this.profile.faxPlcNo.length <= 3 &&
        this.profile.faxPlcNo.length >= 1 && !this.isEmpty(this.profile.faxOfcNo) && !this.isEmpty(this.profile.faxPlcNo)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fax'))
        this.faxCls = ''
      }

      if (this.profile.mbpBsnNo.length <= 3 && this.profile.mbpBsnNo.length >= 1 && this.profile.mbpOfcNo.length <= 12 &&
        this.profile.mbpOfcNo.length >= 1 && !this.isEmpty(this.profile.mbpBsnNo) && !this.isEmpty(this.profile.mbpOfcNo)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#mbp'))
        this.mbpCls = ''
      }

      if (!this.isEmpty(this.profile.mdtyPsnlInfCltnCnstYn)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#rdo3'))
      }

      if (!this.isEmpty(this.profile.choPsnlInfCltnCnstYn)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#rdo5'))
      }

      if (!this.isEmpty(this.profile.recvCnstYn)) {
        this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#rdo7'))
      }
    },
    getCountryCodeList () {
      ProfileApi.getCountryCodeList().then((response) => {
          this.countryCodeList = response.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getAlarmCodeList () {
      ProfileApi.getAlarmCodeList().then((response) => {
          this.alarmCodeList = response.data
          if (this.$route.params.checkView === 'fromLogAppv') {
              this.isSetProfile = true
              this.isAddProfile = true
              this.profile = this.$_.cloneDeep(this.$route.params.profileData)

              if (!this.isEmpty(this.profile.fileNm)) {
                this.thumbnail_type = this.profile.fileNm
              } else {
                this.thumbnail_type = 'input'
              }

              this.$nextTick(() => {
                if (!this.isEkmtc) {
                  this.$refs.autoComplPicNm.keyword = this.profile.picNm
                }
              })
          } else {
            this.profile = this.emptyProfile()
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getChargeCodeList () {
      ProfileApi.getChargeCodeList().then((response) => {
          this.chargeCodeList = response.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getProfileList () {
      ProfileApi.getProfileList(sessionStorage.getItem('loginId'), this.auth.userCtrCd).then((response) => {
          this.profileList = this.parseProfileList(response.data)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getSimpleProfileList () {
      ProfileApi.getSimpleProfileList(sessionStorage.getItem('loginId')).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].idx = i
            response.data[i].picNm = response.data[i].picNm !== undefined ? response.data[i].picNm.toUpperCase() : ''
          }
          this.simpleProfileList = response.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    parseProfileList (arr) {
      let res = []
      let myIdx = null
      if (!this.isEmpty(this.selectedProfile)) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].picNo === this.selectedProfile.picNo && arr[i].cstCatCd === this.selectedProfile.cstCatCd) {
            res.push(arr[i])
            myIdx = i
            break
          }
        }
      }

      if (myIdx != null) {
        arr.splice(myIdx, 1)
      }
      for (let i = 0; i < arr.length; i++) {
        res.push(arr[i])
      }
      for (let i = 0; i < res.length; i++) {
        if (this.isEmpty(res[i].picPoto)) {
          res[i].picPotoString = null
        } else {
          let image = new Image()
          image.src = 'data:image/png;base64,' + res[i].picPoto
          res[i].picPotoString = image.src
        }
      }
      return this.orderByUseYnProfileList(res)
    },
    orderByUseYnProfileList (arr) {
      let res = []
      let noUseArr = []
      for (let p of arr) {
        p.cls = 'img '
        if (p.useYn === 'N') {
          noUseArr.push(p)
        } else {
          res.push(p)
        }
      }

      for (let noUse of noUseArr) {
        noUse.cls += ' no-use'
        res.push(noUse)
      }
      return res
    },
    getProfile (idx, cstNo, cstCatCd, picNo) {
      if (this.isEkmtc && idx !== null) {
        let id = sessionStorage.getItem('loginId').replace('EKMTC_', '')
        if (this.profileList[idx].userId !== id) {
          let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$i18n.t('msg.SETT060G010.018'),
            useIcon: false,
            customClass: 'simple_alert',
            message: '본인의 프로필만 선택할 수 있습니다.',
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
          return
        }
      }
      this.isAddProfile = false

      let profile = null
      let queryParams = ''
      if (idx === null) {
        queryParams = '?cstNo=' + cstNo + '&cstCatCd=' + cstCatCd + '&picNo=' + picNo
      } else {
        profile = JSON.parse(JSON.stringify(this.profileList[idx]))
        queryParams = '?cstNo=' + profile.cstNo + '&cstCatCd=' + profile.cstCatCd + '&picNo=' + profile.picNo
      }

      ProfileApi.getProfile(queryParams).then((response) => {
          let profile = response.data.profile
          this.originPicAlsNm = profile.picAlsNm
          if (!this.isEkmtc) {
            this.$refs.autoComplPicNm.keyword = profile.picNm
          }
          // 코드별 알림 항목 세팅
          profile.alarm = this.makeEmptyAlarmList('EXIST')

          profile = this.parseAlarmList(profile, response.data.alarmList)
          profile.shjList = response.data.shjList

          if (this.isEmpty(profile.useYn)) {
            profile.useYn = 'Y'
          }

          if (this.isEmpty(profile.eiCatCd)) {
            profile.eiCatCd = 'A'
          }

          for (let i = 0; i < profile.shjList.length; i++) {
            if (this.isEmpty(profile.shjList[i].fromCtrCd)) {
              profile.shjList[i].fromCtrCd = null
            }
            if (this.isEmpty(profile.shjList[i].toCtrCd)) {
              profile.shjList[i].toCtrCd = null
            }
          }

          this.uploadFileInfo = []
          this.uploadFileName = ''

          if (!this.isEmpty(profile.picPoto)) {
            this.uploadFileInfo = [
              {
                guid: '1',
                originalName: 'temp_file_name.png',
                size: '1024',
                fileId: 'http://user.ekmtc:9000/user/file-download?cstNo=' + profile.cstNo + '&picNo=' + profile.picNo + '&type=PROFILE_IMAGE',
                status: 'complete',
                contentType: 'image/png',
                category: '',
                isNew: false
              }
            ]
            this.uploadFileName = 'temp_file_name.png'
            this.thumbnail_type = 'input'
          } else {
            this.uploadFileInfo = []
            this.uploadFileName = ''
            if (!this.isEmpty(profile.fileNm)) {
              this.thumbnail_type = profile.fileNm
            } else {
              this.thumbnail_type = 'input'
            }
          }
          if (idx === null) {
            profile.useYn = 'Y'
          }

          // if (profile.mdtyPsnlInfCltnCnstYn !== 'Y') {
          //   this.$router.push(
          //       {
          //         name: 'login-approval',
          //         params: {
          //           checkView: 'selectInProfile',
          //           profileIdx: idx,
          //           profile: profile
          //         }
          //       })
          //       .catch(() => {})
          // } else {
            this.profile = profile
          // }

          if (this.$route.params.checkView === 'updateProfile' && this.$route.params.profileData.userId === this.profile.userId) {
            this.selectedIdx = this.$route.params.profileIdx
          } else {
            this.selectedIdx = idx
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    // icc code Collection WB009 코드의 Inqury Order로 항목 구분하여 코드명, 코드 세팅함
    makeEmptyAlarmList (type) {
      //CD가 바뀌면 코드 수정 필요..
      let alarm = { bl: {}, an: {}, auto: { ship: [], req: [], anno: [] } }
      for (let i = 0; i < this.alarmCodeList.length; i++) {
        let code = {
          title: this.alarmTitleList[i],
          cstNo: null,
          picNo: null,
          altBizCatCd: this.alarmCodeList[i].cd,
          badgeRecvYn: 'N',
          pushRecvYn: 'N',
          talkRecvYn: 'N',
          emlRecvYn: 'N',
          smsRecvYn: 'N',
          faxRecvYn: 'N',
          blRecvYn: 'N',
          selectArr: [], //checkbox
          selectValue: '' //radio
        }

        if (parseInt(this.alarmCodeList[i].inqOrd) === 10) {
          code.emlRecvYn = 'Y'
          code.selectValue = 'eml'
          alarm.bl = code
        } else if (parseInt(this.alarmCodeList[i].inqOrd) === 20) {
          alarm.an = code
        } else {
          if (type === 'NEW') {
            let defaultYn = 'N'
            let selectArr = []
            if (!this.isEmpty(this.memberDetail.recvCnstYn)) {
              if (this.memberDetail.recvCnstYn === 'Y') {
                defaultYn = 'Y'
                selectArr = ['push', 'talk', 'eml', 'sms']
              }
            }
            code.selectArr = selectArr
            code.badgeRecvYn = defaultYn
            code.pushRecvYn = defaultYn
            code.talkRecvYn = defaultYn
            code.emlRecvYn = defaultYn
            code.smsRecvYn = defaultYn
          }

          // 선적진행현황
          if (parseInt(this.alarmCodeList[i].inqOrd) >= 30 && parseInt(this.alarmCodeList[i].inqOrd) <= 120) {
            if (this.memberDetail.userCtrCd !== 'JP') {
              if (this.memberDetail.userCtrCd !== 'KR') {
                if (code.altBizCatCd !== '23' && code.altBizCatCd !== '24') {
                  alarm.auto.ship.push(code)
                }
              } else {
                if (code.altBizCatCd !== '24') {
                  alarm.auto.ship.push(code)
                }
              }
            } else {
              if (code.altBizCatCd !== '23') {
                alarm.auto.ship.push(code)
              }
            }
            // 요청처리현황
          } else if (parseInt(this.alarmCodeList[i].inqOrd) >= 130 && parseInt(this.alarmCodeList[i].inqOrd) <= 180) {
            alarm.auto.req.push(code)
            // 안내사항
          } else if (parseInt(this.alarmCodeList[i].inqOrd) >= 190 && parseInt(this.alarmCodeList[i].inqOrd) <= 230) {
            alarm.auto.anno.push(code)
          }
        }
      }
      this.isSetProfile = true
      return alarm
    },
    // 자동알림 서비스 신청 항목 추가시 icc code collection 에서 item 알림, code no WB009로 조회하여
    // code, Inqury Order 항목별 구분하여 세팅하고, 여기에서 해당 항목에 맞게 코드 처리 해줘야 함.
    // ex. 공통코드 25번 'T/S 모선 변경 알림' 추가하게 되면 altBizCatCd가 25번 세팅 되므로, 처리할 위치 25번 추가해줘야 함
    parseAlarmList (profile, alarmList) {
      for (let i = 0; i < alarmList.length; i++) {
        // 선적진행현황
        if ((parseInt(alarmList[i].altBizCatCd) >= 3 && parseInt(alarmList[i].altBizCatCd) <= 11) ||
            (parseInt(alarmList[i].altBizCatCd) >= 22 && parseInt(alarmList[i].altBizCatCd) <= 25)) {
          for (let j = 0; j < profile.alarm.auto.ship.length; j++) {
            if (profile.alarm.auto.ship[j].altBizCatCd === alarmList[i].altBizCatCd) {
              profile.alarm.auto.ship[j].cstNo = alarmList[i].cstNo
              profile.alarm.auto.ship[j].picNo = alarmList[i].picNo
              if (alarmList[i].badgeRecvYn === 'Y') {
                profile.alarm.auto.ship[j].selectArr.push('badge')
              }
              if (alarmList[i].pushRecvYn === 'Y') {
                profile.alarm.auto.ship[j].selectArr.push('push')
              }
              if (alarmList[i].talkRecvYn === 'Y') {
                profile.alarm.auto.ship[j].selectArr.push('talk')
              }
              if (alarmList[i].emlRecvYn === 'Y') {
                profile.alarm.auto.ship[j].selectArr.push('eml')
              }
              if (alarmList[i].smsRecvYn === 'Y') {
                profile.alarm.auto.ship[j].selectArr.push('sms')
              }
              break
            }
          }
          // 요청처리현황
        } else if (parseInt(alarmList[i].altBizCatCd) >= 12 && parseInt(alarmList[i].altBizCatCd) <= 17) {
          for (let j = 0; j < profile.alarm.auto.req.length; j++) {
            if (profile.alarm.auto.req[j].altBizCatCd === alarmList[i].altBizCatCd) {
              profile.alarm.auto.req[j].cstNo = alarmList[i].cstNo
              profile.alarm.auto.req[j].picNo = alarmList[i].picNo
              if (alarmList[i].badgeRecvYn === 'Y') {
                profile.alarm.auto.req[j].selectArr.push('badge')
              }
              if (alarmList[i].pushRecvYn === 'Y') {
                profile.alarm.auto.req[j].selectArr.push('push')
              }
              if (alarmList[i].talkRecvYn === 'Y') {
                profile.alarm.auto.req[j].selectArr.push('talk')
              }
              if (alarmList[i].emlRecvYn === 'Y') {
                profile.alarm.auto.req[j].selectArr.push('eml')
              }
              if (alarmList[i].smsRecvYn === 'Y') {
                profile.alarm.auto.req[j].selectArr.push('sms')
              }
              break
            }
          }
          // 안내사항
        } else if ((parseInt(alarmList[i].altBizCatCd) >= 18 && parseInt(alarmList[i].altBizCatCd) <= 21) || parseInt(alarmList[i].altBizCatCd) === 26) {
          for (let j = 0; j < profile.alarm.auto.anno.length; j++) {
            if (profile.alarm.auto.anno[j].altBizCatCd === alarmList[i].altBizCatCd) {
              profile.alarm.auto.anno[j].cstNo = alarmList[i].cstNo
              profile.alarm.auto.anno[j].picNo = alarmList[i].picNo
              if (alarmList[i].badgeRecvYn === 'Y') {
                profile.alarm.auto.anno[j].selectArr.push('badge')
              }
              if (alarmList[i].pushRecvYn === 'Y') {
                profile.alarm.auto.anno[j].selectArr.push('push')
              }
              if (alarmList[i].talkRecvYn === 'Y') {
                profile.alarm.auto.anno[j].selectArr.push('talk')
              }
              if (alarmList[i].emlRecvYn === 'Y') {
                profile.alarm.auto.anno[j].selectArr.push('eml')
              }
              if (alarmList[i].smsRecvYn === 'Y') {
                profile.alarm.auto.anno[j].selectArr.push('sms')
              }
              break
            }
          }
        }
      }
      return profile
    },
    paresReqAlarm (alarm) {
      let alarms = []
      alarms.push(alarm.bl)
      alarms.push(alarm.an)
      for (let a of alarm.auto.ship) {
        alarms.push(a)
      }
      for (let a of alarm.auto.req) {
        alarms.push(a)
      }
      for (let a of alarm.auto.anno) {
        alarms.push(a)
      }
      return alarms
    },
    collapseProfile (type) {
      if (type === 'open') {
        this.max = this.profileList.length
      } else if (type === 'close') {
        this.max = max
      }
    },
    showTooltip (flag) {
      if (!this.isToolTipWait) {
        this.isToolTip = flag
        this.isToolTipWait = true
      }
      setTimeout(() => {
        this.isToolTipWait = false
      }, 100)
    },
    resetProfile (param) {
      if (param === 'ADD') {
        // this.isAddProfile = true
      }
      this.autoSelectPic = null
      this.selectedIdx = null
      this.$refs.autoComplPicNm.clear()

      this.profile = this.emptyProfile()
    },
    emptyProfile () {
      const profile = {
        cstNo: null,
        cstCatCd: '01',
        eiCatCd: 'A',
        picNm: null,
        picAlsNm: null,
        picEnm: null,
        telPlcNo: null,
        telOfcNo: null,
        faxPlcNo: null,
        faxOfcNo: null,
        mbpBsnNo: null,
        mbpOfcNo: null,
        useYn: 'Y',
        shjList: [],
        alarm: this.makeEmptyAlarmList('NEW'),
        picPotoString: null,
        fileNm: null,
        picBizCatCd: '01'
      }

      this.uploadFileInfo = []
      this.uploadFileName = ''
      this.thumbnail_type = 'input'
      this.originPicAlsNm = null
      this.isEmptyProfile = true

      return profile
    },
    setReqAlarm (alarm) {
      alarm.an.badgeRecvYn = 'N'
      alarm.an.blRecvYn = 'N'
      alarm.an.emlRecvYn = 'N'
      alarm.an.faxRecvYn = 'N'
      alarm.an.pushRecvYn = 'N'
      alarm.an.smsRecvYn = 'N'
      alarm.an.talkRecvYn = 'N'
      alarm.bl.badgeRecvYn = 'N'
      alarm.bl.blRecvYn = 'N'
      alarm.bl.emlRecvYn = 'N'
      alarm.bl.faxRecvYn = 'N'
      alarm.bl.pushRecvYn = 'N'
      alarm.bl.smsRecvYn = 'N'
      alarm.bl.talkRecvYn = 'N'
      for (let i = 0; i < alarm.auto.ship.length; i++) {
        alarm.auto.ship[i].badgeRecvYn = 'N'
        alarm.auto.ship[i].blRecvYn = 'N'
        alarm.auto.ship[i].emlRecvYn = 'N'
        alarm.auto.ship[i].faxRecvYn = 'N'
        alarm.auto.ship[i].pushRecvYn = 'N'
        alarm.auto.ship[i].smsRecvYn = 'N'
        alarm.auto.ship[i].talkRecvYn = 'N'
      }
      for (let i = 0; i < alarm.auto.req.length; i++) {
        alarm.auto.req[i].badgeRecvYn = 'N'
        alarm.auto.req[i].blRecvYn = 'N'
        alarm.auto.req[i].emlRecvYn = 'N'
        alarm.auto.req[i].faxRecvYn = 'N'
        alarm.auto.req[i].pushRecvYn = 'N'
        alarm.auto.req[i].smsRecvYn = 'N'
        alarm.auto.req[i].talkRecvYn = 'N'
      }
      for (let i = 0; i < alarm.auto.anno.length; i++) {
        alarm.auto.anno[i].badgeRecvYn = 'N'
        alarm.auto.anno[i].blRecvYn = 'N'
        alarm.auto.anno[i].emlRecvYn = 'N'
        alarm.auto.anno[i].faxRecvYn = 'N'
        alarm.auto.anno[i].pushRecvYn = 'N'
        alarm.auto.anno[i].smsRecvYn = 'N'
        alarm.auto.anno[i].talkRecvYn = 'N'
      }

      if (alarm.bl.selectValue === 'fax') {
        alarm.bl.faxRecvYn = 'Y'
      } else if (alarm.bl.selectValue === 'eml') {
        alarm.bl.emlRecvYn = 'Y'
      }

      for (let select of alarm.an.selectArr) {
        if (select === 'fax') {
          alarm.an.faxRecvYn = 'Y'
        } else if (select === 'eml') {
          alarm.an.emlRecvYn = 'Y'
        } else if (select === 'sms') {
          alarm.an.smsRecvYn = 'Y'
        } else if (select === 'blCopy') {
          alarm.an.blRecvYn = 'Y'
        }
      }

      for (let a of alarm.auto.ship) {
        for (let select of a.selectArr) {
          if (select === 'badge') {
            a.badgeRecvYn = 'Y'
          } else if (select === 'push') {
            a.pushRecvYn = 'Y'
          } else if (select === 'talk') {
            a.talkRecvYn = 'Y'
          } else if (select === 'eml') {
            a.emlRecvYn = 'Y'
          } else if (select === 'sms') {
            a.smsRecvYn = 'Y'
          }
        }
      }

      for (let a of alarm.auto.req) {
        for (let select of a.selectArr) {
          if (select === 'badge') {
            a.badgeRecvYn = 'Y'
          } else if (select === 'push') {
            a.pushRecvYn = 'Y'
          } else if (select === 'talk') {
            a.talkRecvYn = 'Y'
          } else if (select === 'eml') {
            a.emlRecvYn = 'Y'
          } else if (select === 'sms') {
            a.smsRecvYn = 'Y'
          }
        }
      }

      for (let a of alarm.auto.anno) {
        for (let select of a.selectArr) {
          if (select === 'badge') {
            a.badgeRecvYn = 'Y'
          } else if (select === 'push') {
            a.pushRecvYn = 'Y'
          } else if (select === 'talk') {
            a.talkRecvYn = 'Y'
          } else if (select === 'eml') {
            a.emlRecvYn = 'Y'
          } else if (select === 'sms') {
            a.smsRecvYn = 'Y'
          }
        }
      }

      return alarm
    },
    addShj () {
      this.profile.shjList.push({
        cstNo: null,
        picNo: null,
        seq: null,
        fromCtrCd: this.defaultCtrCd,
        toCtrCd: null,
        shprCd: null,
        cneCd: null
      })
    },
    deleteShj (idx) {
      this.profile.shjList.splice(idx, 1)
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
        return true
      } else {
        return false
      }
    },
    getObjData (vo) {
      this.currShjIdx = vo.idx
      this.currShjType = vo.type
    },
    updatePicNm (vo) {
      //TODO 선택하시겠습니까?
      this.autoSelectPic = vo
      let obj = {
        onConfirm: this.getProfileAutoSelect,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$i18n.t('msg.MYIN050T010.057'),
        customConfirmBtnText: this.$i18n.t('msg.MYIN050T010.056'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: this.$t('msg.MYIN050T010.080'),
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getProfileAutoSelect () {
      this.getProfile(
        null,
        this.autoSelectPic.cstNo,
        this.autoSelectPic.cstCatCd,
        this.autoSelectPic.picNo
      )
    },
    updateShj (vo) {
      setTimeout(() => {
        if (this.currShjType === 'shpr') {
          this.profile.shjList[this.currShjIdx].shprCd = vo.cd
          this.profile.shjList[this.currShjIdx].shprNm = vo.cdNm
        } else if (this.currShjType === 'cne') {
          this.profile.shjList[this.currShjIdx].cneCd = vo.cd
          this.profile.shjList[this.currShjIdx].cneNm = vo.cdNm
        }
      }, 50)
    },
    updateShjV2 (vo) {
      this.profile.shjList[vo.idx].shprCd = vo.cd
      this.profile.shjList[vo.idx].shprNm = vo.cdNm
    },
    updateCurrShj (idx, type) {
      this.currShjIdx = idx
      this.currShjType = type
    },
    uploadProfileImage (fileInfo) {
      this.uploadFileInfo[0].isNew = true
    },
    openFileUploader () {
      this.isShowDext5 = true
      this.dext5Idx++
      this.$ekmtcCommon.layerOpen('#dext5_pop > .popup_dim')
    },
    closeFileUploadr () {
      this.isShowDext5 = false
      this.$ekmtcCommon.layerClose('#dext5_pop > .popup_dim')
    },
    customAlert (msg) {
      let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$i18n.t('msg.SETT060G010.018'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getMyProfile (idx) {
      if (this.isEmpty(this.selectedProfile)) {
        return false
      } else if (this.isEmpty(this.profileList[idx].picNo) || this.isEmpty(this.selectedProfile.picNo)) {
        return false
      } else if (this.profileList[idx].picNo === this.selectedProfile.picNo && this.profileList[idx].cstCatCd === this.selectedProfile.cstCatCd) {
        return true
      } else {
        return false
      }
    },
    getFilePath (fileNm) {
      try {
        let path = require('@/assets/images/myin/' + fileNm)
        return path
      } catch (error) {
        return null
      }
    },
    getImgCls (idx) {
      let cls = 'img'
      if (this.seletedIdx === idx && !this.getMyProfile(idx)) {
        cls += ' selected-profile'
      }
      this.profileList[idx].cls = cls
    },
    getAlarmLength () {
      let o = {
        ship: 0,
        req: 0,
        anno: 0
      }

      if (this.auth.userCtrCd === 'KR') {
        o.ship = this.profile.alarm.auto.ship.length
        o.req = this.profile.alarm.auto.req.length
        o.anno = this.profile.alarm.auto.anno.length
      } else {
        Object.keys(this.profile.alarm.auto).forEach((item, index) => {
          //console.log(item)
          let cnt = 0
          this.profile.alarm.auto[item].forEach((_item) => {
            switch (_item.altBizCatCd) {
              case '11':
                if (this.auth.userCtrCd === 'JP' || this.auth.userCtrCd === 'ID') {
                  cnt++
                } else {
                  //
                }
                break
              case '09':
              case '10':
              case '12':
              case '15':
                if (this.auth.userCtrCd === 'JP') {
                  cnt++
                } else {
                  //
                }
                break
              case '18':
                //
                break
              default:
                cnt++
              //
            }
          })
          //console.log('@@@@ cnt: ', cnt)
          o[item] = cnt
        })
      }
      o.ship = o.ship + 1
      o.req = o.req + 1
      o.anno = o.anno + 1

      // :rowspan="profile.alarm.auto.ship.length + 1"
      //console.log('@@@@ o: ', o)
      return o
    },
    showAlarm (alarm) {
      let b = false
      //console.log('@@@@ alarm: ', alarm)
      //console.log(this.auth.userCtrCd)
      if (this.auth.userCtrCd === 'KR') {
        b = true
        if (alarm.altBizCatCd === '26') { // S/R submission & BL confirmation, MY만 노출 설정함.
          b = false
        }
      } else {
        switch (alarm.altBizCatCd) {
          case '11':
            if (this.auth.userCtrCd === 'JP' || this.auth.userCtrCd === 'ID') {
              b = true
            } else {
              b = false
            }
            break
          case '09':
          case '10':
          case '12':
          case '15':
            if (this.auth.userCtrCd === 'JP') {
              b = true
            } else {
              b = false
            }
            break
          case '18':
            b = false
            break
          case '26': // NEW DAILY BATH, MY만 노출 설정함.
            if (this.auth.userCtrCd === 'MY') {
              b = true
            } else {
              b = false
            }
            break
          default:
            b = true
        }
      }
      return b
    },
    showTooltipA (e) {
      // console.log('showTooltip', e)
      const el = e.target
      const parentEl = el.parentElement.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'inline-block'
    },
    hideTooltipA (e) {
      // console.log('hideTooltip', e)
      const el = e.target
      const parentEl = el.parentElement.parentElement
      parentEl.querySelector('.tooltip_wrap').style.display = 'none'
    },
    openPopup (compNm) {
      this.customComponent = compNm

      if (compNm === 'MyInfoPersonalAgreePop') {
        this.popupParams = {
          mdtyPsnlInfCltnCnstYn: this.profile.mdtyPsnlInfCltnCnstYn
        }
      } else if (compNm === 'MyInfoPersonalAgreeChoicePop') {
        this.popupParams = {
          choPsnlInfCltnCnstYn: this.profile.choPsnlInfCltnCnstYn
        }
      } else if (compNm === 'MyInfoMarketingAgree') {
        this.popupParams = {
          recvCnstYn: this.profile.recvCnstYn
        }
      } else {
        this.popupParams = {}
      }

      this.$ekmtcCommon.layerOpen('#layerPop > .popup_dim')
    },
    closePoup () {
      this.customComponent = null

      this.$ekmtcCommon.layerClose('#layerPop > .popup_dim')
    },
    agreementPop (chk, gubun) {
      if (gubun === 'MPAP') {
        this.profile.mdtyPsnlInfCltnCnstYn = chk
      } else if (gubun === 'MPACP') {
        this.profile.choPsnlInfCltnCnstYn = chk
      } else if (gubun === 'MMA') {
        this.profile.recvCnstYn = chk
      }
    }
  }
}
</script>
<style scoped>
.checkbg {
  background: #efefef;
}
.selected-profile {
  outline: 2px solid #36a7f7;
}
.no-use {
  position: relative;
  display: flex;
  opacity: 0.6;
}
.no-use > span {
  font-weight: bold;
  position: absolute;
  width: inherit;
  text-align: center;
  padding-top: 25px;
}
.my > .no-use > img {
  width: 66px !important;
  padding-left: 2px !important;
}
</style>
